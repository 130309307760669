<template>
  <div>
    <div v-if="step == 1" class="b2c-o-h">
      <div class="container__sp">
        <b-card class="cart-body__sp">
          <div class="info-phone-number__sp mb-1">
            <h1 class="cart-title mb-3">{{ $t("ServicePage.Sim number") }}</h1>
            <div class="divide-line mb-2dot5"></div>
            <div class="big-number mb-3">
              {{ formatPhoneNumber(phoneNumber) }}
            </div>
            <div class="fee-info mb-2dot5">
              <div>
                {{
                  type == "sim"
                    ? $t("SupportPage.FillInfoReIssuanceFeeSIM")
                    : $t("SupportPage.FillInfoReIssuanceFeeESIM")
                }}
              </div>
              <div>{{ isInIframe ? formatPrice(changeSimFee) : formatPrice(totalPrice - shipmentFee) }}đ</div>
            </div>
            <div class="fee-info">
              <div>{{ $t("SupportPage.FillInfoShipFee") }}</div>
              <div>{{ formatPrice(shipmentFee) }}đ</div>
            </div>
            <div class="divide-line mt-4 mb-2"></div>
            <div class="cart-footer">
              <div class="footer-title">
                {{ $t("SupportPage.FillInfoTotalFee") }}
              </div>
              <div class="footer-value">{{ isInIframe ? formatPrice(changeSimFee + shipmentFee) : formatPrice(totalPrice) }}đ</div>
            </div>
          </div>
          <div class="p-2 right-content">
            <div class="recive-info-title mb-1">
              {{
                type == "sim"
                  ? $t("SupportPage.FillInfoReceivedSIM")
                  : $t("SupportPage.FillInfoReceivedESIM")
              }}
            </div>
            <b-card-text>
              <validation-observer ref="paymentRefs">
                <div class="row row-info">
                  <div class="col-md-6">
                    <validation-provider #default="{ errors }" rules="required">
                      <label class="d-block fw-b"
                        >{{ $t("SupportPage.FillInfoFullName") }}
                        <span style="color: red">*</span>
                      </label>
                      <b-form-input
                        class="bs-2"
                        :placeholder="$t('SupportPage.FillInfoReceiveNamePlaceHolder')"
                        size="sm1"
                        maxlength="40"
                        v-model="cart_info.fullName"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="fs-error-txt" v-if="errors.length > 0">{{
                        $t("SupportPage.FillInfoFullNameErr")
                      }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-md-6">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|phoneNumber"
                    >
                      <label class="d-block fw-b"
                        >{{ $t("SupportPage.FillInfoPhone") }}
                        <span style="color: red">*</span>
                      </label>
                      <b-form-input
                        class="bs-2"
                        :placeholder="$t('SupportPage.FillInfoPhonePlaceHolder')"
                        size="sm1"
                        v-model.trim="cart_info.ContactPhone"
                        @keydown="isNumber"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="fs-error-txt" v-if="errors.length > 0">{{
                        $t("SupportPage.FillInfoPhoneErr")
                      }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-md-6" v-if="type == 'sim' && isInIframe">
                    <label class="d-block fw-b">Hình thức giao hàng </label>
                    <v-select
                      label="text"
                      v-model="cart_info.shipment_type"
                      :options="[
                        {
                          id: 'home',
                          text: 'Giao tận nơi',
                        },
                         {
                          id: 'itel',
                          text: 'Nhận tại quầy',
                        },
                      ]"
                      :clearable="false"
                      :reduce="(c) => c.id"
                      @input="change_shipment"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t("SupportPage.NoData") }}
                      </template>
                    </v-select>
                  </div>
                </div>
                <div v-if="type == 'sim'">
                  <div class="select-method-receive mt-2" v-if="!isInIframe">
                    <div class="receive-method">
                      <div>
                        {{ $t("ServicePage.Sim receiving address") }}
                      </div>
                      <b-form-checkbox
                        @change="change_shipment('home')"
                        value="home"
                        name="shipment_type"
                        v-model="cart_info.shipment_type"
                      ></b-form-checkbox>
                    </div>
                    <div class="receive-method">
                      <div>
                        {{ $t("ServicePage.Receive at the ITEL agent room") }}
                      </div>
                      <b-form-checkbox
                        @change="change_shipment('itel')"
                        value="itel"
                        name="shipment_type"
                        v-model="cart_info.shipment_type"
                      ></b-form-checkbox>
                    </div>
                  </div>
                  <div
                    class="row mb-2"
                    v-if="!isLoading && cart_info.shipment_type == 'home'"
                  >
                    <div class="col-md-12 mb-2">
                      <validation-provider #default="{ errors }" rules="required|min:2">
                        <label class="d-block fw-b"
                          >{{ $t("SupportPage.FillInfoAddress") }}
                          <span style="color: red">*</span>
                        </label>
                        <b-form-input
                          class="bs-2"
                          :placeholder="$t('SupportPage.FillInfoAddressPlaceHolder')"
                          size="sm1"
                          v-model="cart_info.addr"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("SupportPage.FillInfoAddressErr")
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-6 mb-1">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|addressValidate"
                      >
                        <v-select
                          :class="[{ 'error-border': errors.length > 0 }, 'bs-2']"
                          :placeholder="$t('Common.Province / City')"
                          label="text"
                          v-model="CityId"
                          :filter="fuseSearch"
                          :options="Cities"
                          :reduce="(CityId) => CityId.id"
                        >
                          <template #no-options="{ search, searching, loading }">
                            {{ $t("SupportPage.NoData") }}
                          </template>
                        </v-select>
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("Common.Province / City Error")
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-6">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|addressValidate"
                      >
                        <v-select
                          :class="[{ 'error-border': errors.length > 0 }, 'bs-2']"
                          :placeholder="$t('Common.District')"
                          :disabled="!(CityId > 0)"
                          :filter="fuseSearch"
                          label="text"
                          v-model="DistrictId"
                          :options="Districts"
                          :reduce="(c) => c.id"
                        >
                          <template #no-options="{ search, searching, loading }">
                            {{ $t("SupportPage.NoData") }}
                          </template>
                        </v-select>
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("Common.DistrictErr")
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-6">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|addressValidate"
                      >
                        <v-select
                          :class="[{ 'error-border': errors.length > 0 }, 'bs-2']"
                          :placeholder="$t('Common.Ward')"
                          :disabled="!(DistrictId > 0)"
                          :filter="fuseSearch"
                          label="text"
                          v-model="WardId"
                          :options="Wards"
                          :reduce="(c) => c.id"
                        >
                          <template #no-options="{ search, searching, loading }">
                            {{ $t("SupportPage.NoData") }}
                          </template>
                        </v-select>
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("Common.WardErr")
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-6">
                      <validation-provider #default="{ errors }" rules="">
                        <v-select
                          class="bs-2"
                          :placeholder="$t('Common.Hamlet / Area / Building')"
                          :disabled="!(WardId > 0)"
                          :filter="fuseSearch"
                          label="text"
                          v-model="HamletId"
                          :options="Hamlets"
                          :reduce="(c) => c.id"
                        >
                          <template #no-options="{ search, searching, loading }">
                            {{ $t("SupportPage.NoData") }}
                          </template>
                        </v-select>
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("Common.Hamlet / Area / Building Err")
                        }}</small>
                      </validation-provider>
                    </div>
                  </div>
                  <b-row v-else>
                    <div class="col-12 mt-1">
                      <div
                        @click="onClickChooseStore()"
                        :class="
                          storeCollapse ? 'b2c-store-colapse' : 'b2c-store-nocolapse'
                        "
                        class="border d-block bg-white p-2 select-store"
                      >
                        <span v-if="!isLoading && cart_info.StoreId > 0">{{
                          Stores.find((m) => m.Id == cart_info.StoreId).Name
                        }}</span>
                        <span v-else>{{ $t("ServicePage.PickAgency") }}</span>
                      </div>
                    </div>
                  </b-row>
                  <div v-if="!storeCollapse && cart_info.shipment_type == 'itel'">
                    <div
                      v-on:click="selectStore(i)"
                      class="ml-1 mr-1 p-2 bg-white border mb-2"
                      style="cursor: pointer"
                      v-for="i in Stores"
                      :key="i.Id"
                    >
                      <h2 class="h2 pt-1">{{ i.Name }}</h2>
                      <p class="text">{{ i.Address }}</p>
                      <p class="pt-1 text text-primary pb-1">
                        <a
                          target="_blank"
                          class="text text-primary"
                          :href="
                            'http://maps.google.com/maps/dir/?api=1&destination=' +
                            i.Lat +
                            ',' +
                            i.Long +
                            '&travelmode=driving'
                          "
                          :title="$t('Common.Direct')"
                          ><feather-icon icon="MapPinIcon" /> {{ $t("Common.Direct") }}</a
                        >
                      </p>
                    </div>
                  </div>
                  <div v-if="!isInIframe" class="mt-2 mb-1 policy-agree">
                    <b-form-checkbox
                      :value="true"
                      v-model="isAgreePolicies"
                    ></b-form-checkbox>
                    <div style="padding-top: 8px">
                      {{ $t("SupportPage.ExchangeTermCondition1") }}
                      <a
                        href="https://itel.vn/thong-tin/mua-sim-so-itel"
                        class="text-primary"
                        target="_blank"
                      >
                        <span style="color: red; cursor: pointer">{{
                          $t("SupportPage.SimPolicity")
                        }}</span>
                      </a>
                      {{ $t("SupportPage.of iTel") }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row row-info mb-2">
                    <div class="col-md-12">
                      <validation-provider #default="{ errors }" rules="required|email">
                        <label class="d-block fw-b"
                          >{{
                            type == "esim"
                              ? $t("ServicePage.Email to receive ESim")
                              : $t("ServicePage.Email to receive Sim")
                          }}
                          <span style="color: red">*</span></label
                        >
                        <b-form-input
                          class="bs-2"
                          :placeholder="$t('ServicePage.Your Email')"
                          size="sm1"
                          maxlength="40"
                          v-model.trim="cart_info.email"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("ServicePage.Your email error")
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-md-12" v-if="isInIframe">
                      <label class="d-block fw-b"
                        >Ảnh chuyển khoản <span style="color: red">*</span></label
                      >
                      <label ref="inputPledgeKey" :for="inputPledgeKey" style="cursor: pointer; position: relative" class="label-pledge-container">
                        <input
                          type="file"
                          hidden
                          :name="inputPledgeKey"
                          :id="inputPledgeKey"
                          accept="image/png, image/jpeg"
                          @change="onLoadPledge"
                        />
                        <img width="100" height="100" alt="img" :src="pledgeImgSrc" />
                        <button class="btn-preview-image-container" v-if="isSelectImage">
                          <img src="@/assets/images/preview.png" alt=""  @click="onPreviewPledgeImage()"/>
                          <img src="@/assets/images/edit.png" alt="" @click="onEditPledgeImage()"/>
                        </button>
                      </label>
                      <!-- <small class="fs-error-txt" v-if="errors.length > 0">{{
                          $t("ServicePage.Your email error")
                        }}</small> -->
                    </div>
                    <div v-if="!isInIframe" class="mt-2 mb-1 ml-1 policy-agree">
                      <b-form-checkbox
                        :value="true"
                        v-model="isAgreePolicies"
                      ></b-form-checkbox>
                      <div style="padding-top: 8px">
                        {{ $t("SupportPage.ExchangeTermCondition1") }}
                        <a
                          href="https://itel.vn/thong-tin/mua-sim-so-itel"
                          class="text-primary"
                          target="_blank"
                        >
                          <span style="color: red; cursor: pointer">{{
                            $t("SupportPage.SimPolicity")
                          }}</span>
                        </a>
                        {{ $t("SupportPage.of iTel") }}
                      </div>
                    </div>
                    <!-- <b-form-checkbox :value="true" v-model="cart_info.agree">
                    Tôi đồng ý với <a href="https://itel.vn/thong-tin/mua-sim-so-itel" class="text-primary" target="_blank">chính sách mua SIM số</a> của iTel
                  </b-form-checkbox> -->
                  </div>
                </div>
              </validation-observer>
<!--              <div v-if="isInIframe && type == 'sim' && cart_info.shipment_type == 'home'" class="mt-2 mb-1 ml-1 policy-agree" style="justify-content: space-between">-->
<!--                <div style="padding-top: 8px">-->
<!--                  Giao hàng hỏa tốc-->
<!--                  <small>(Không áp dụng với hình thức thanh toán COD)</small>-->
<!--                  <br />-->
<!--                  <small class="text-primary">(Chỉ áp dụng cho đơn nhận tại Hà Nội)</small>-->
<!--                </div>-->
<!--                <b-form-checkbox-->
<!--                  :disabled="CityId != 1"-->
<!--                  class="ml-1"-->
<!--                  value="express"-->
<!--                  v-model="deliveryMethodIframe"-->
<!--                  @change="onChangeDelivery('express')"-->
<!--                ></b-form-checkbox>-->
<!--              </div>-->
              <hr v-if="type == 'sim' && isInIframe && cart_info.shipment_type == 'home'"/>
              <div
                v-if="isInIframe && type == 'sim' && cart_info.shipment_type == 'home'"
                class="mt-2 mb-1 ml-1 policy-agree"
                style="justify-content: space-between"
              >
                <div style="padding-top: 8px">
                  <div>Giao hàng nhanh</div>
                  <div>
                    <small>
                      Nhận hàng từ 1 tới 3 ngày kể từ thời điểm đặt đơn hàng
                    </small>
                  </div>
                </div>
                <b-form-checkbox
                  class="ml-1"
                  value="fast"
                  v-model="deliveryMethodIframe"
                  @change="onChangeDelivery('fast')"
                ></b-form-checkbox>
              </div>
              <!-- select payment method -->
              <div v-if="isInIframe && type == 'sim'" class="mt-2 mb-1 payment-options">
                <label class="title-Options">Chọn hình thức thanh toán</label>
              </div>
              <b-row v-if="isInIframe && type == 'sim'" style="margin-bottom: 15px">
                <b-col cols="12" style="margin-bottom: 10px">
                  <div class="bg-white rounded">
                    <b-form-checkbox
                      value="CK"
                      v-model="paymentMethodIframe"
                      class="vs-checkbox-con mr-1"
                      @change="onChangePaymentMethodIframe('CK')"
                    >
                      <span style="font-size: 14px">Chuyển khoản</span>
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  v-if="paymentMethodIframe == 'CK'"
                  style="margin-bottom: 10px"
                >
                  <div style="display: flex; align-items: center">
                    <span style="font-size: 14px; margin-right: 10px"
                      >Ảnh chuyển khoản <span style="color: red">*</span></span
                    >
                    <label ref="inputPledgeKey" :for="inputPledgeKey" style="cursor: pointer; position: relative" class="label-pledge-container">
                      <input
                        type="file"
                        hidden
                        :name="inputPledgeKey"
                        :id="inputPledgeKey"
                        accept="image/png, image/jpeg"
                        @change="onLoadPledge"
                      />
                      <img width="100" height="100" alt="img" :src="pledgeImgSrc" />
                      <button class="btn-preview-image-container" v-if="isSelectImage">
                        <img src="@/assets/images/preview.png" alt=""  @click="onPreviewPledgeImage()"/>
                        <img src="@/assets/images/edit.png" alt="" @click="onEditPledgeImage()"/>
                      </button>
                    </label>
                  </div>
                </b-col>
<!--                <b-col cols="12" style="margin-bottom: 10px">-->
<!--                  <div :class="deliveryMethodIframe === 'express' && isInIframe ? 'bg-white rounded disabled' : 'bg-white rounded'">-->
<!--                    <b-form-checkbox-->
<!--                      value="COD"-->
<!--                      v-model="paymentMethodIframe"-->
<!--                      class="vs-checkbox-con mr-1"-->
<!--                      @change="onChangePaymentMethodIframe('COD')"-->
<!--                    >-->
<!--                      <span style="font-size: 14px">Thanh toán khi nhận hàng(COD)</span>-->
<!--                    </b-form-checkbox>-->
<!--                  </div>-->
<!--                </b-col>-->
              </b-row>
              <b-row v-if="!isInIframe">
                <b-col cols="12">
                  <div class="bg-white pb-1 rounded">
                    <b-form-checkbox
                      value="VNPAY"
                      v-model="paymentMethod"
                      class="vs-checkbox-con mr-1"
                    >
                      <span>VNPAY QR</span>
                      <b-img
                        width="54px"
                        :src="require('@/assets/images/b2c/logoVNPay.svg')"
                        alt="logoVNPay"
                      />
                    </b-form-checkbox>

                    <b-form-checkbox value="MOMO" v-model="paymentMethod" class="mt-2">
                      <span>Ví MOMO</span>
                      <b-img
                        width="44px"
                        :src="require('@/assets/images/b2c/logoMomo.svg')"
                        alt="logoMomo"
                      />
                    </b-form-checkbox>
                    <b-form-checkbox value="ZALO" v-model="paymentMethod" class="mt-2">
                      <span> ZALOPAY </span>
                      <b-img
                        width="44px"
                        :src="require('@/assets/images/b2c/zalopay.svg')"
                        alt="logoZaloPay"
                      />
                    </b-form-checkbox>
                    <b-form-checkbox value="BANK" v-model="paymentMethod" class="mt-2">
                      <span> Ngân hàng/Thẻ ATM nội địa</span>
                    </b-form-checkbox>
                    <b-form-checkbox value="INTCARD" v-model="paymentMethod" class="mt-2">
                      <span> Thẻ thanh toán quốc tế</span>
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <button v-if="!isInIframe" @click.stop="onConfirm" class="fs-group fs-buts confirm-btn">
                {{ $t("SupportPage.FillInfoSubmit") }}
                <div class="img-icon">
                  <img src="../assets/images/next-white.png" />
                </div>
              </button>
              <button v-if="isInIframe && (type == 'sim' || type == 'esim')" @click.stop="onConfirmSimIframe" :disabled="paymentMethodIframe.length===0" class="fs-group fs-buts confirm-btn">
                {{ $t("SupportPage.FillInfoSubmit") }}
                <div class="img-icon">
                  <img src="../assets/images/next-white.png" />
                </div>
              </button>
            </b-card-text>
          </div>
        </b-card>
      </div>
    </div>
    <div v-else class="b2c-o-h">
      <div class="container__sp">
        <b-card class="cart-body__sp cart-body__sp-2">
          <div class="cart-title mb-2 ta-c fs-3rem">
            {{ $t("SupportPage.FillInfoPhone") }}
          </div>
          <div class="info-phone-number__sp mb-1">
            <h1 class="cart-title mb-2dot5 fs-3rem">
              {{ $t("SupportPage.ExchangeOrderTitle") }}
            </h1>
            <div class="divide-line mb-2dot5"></div>
            <div class="fee-info mb-2dot5">
              <div class="big-number fs-3rem">
                {{ formatPhoneNumber(phoneNumber) }}
              </div>
              <div>{{ formatPrice(totalPrice - shipmentFee) }}đ</div>
            </div>
            <div class="divide-line mb-2"></div>
            <div class="fee-info mb-2">
              <div>{{ $t("SupportPage.FillInfoShipFee") }}</div>
              <div>{{ formatPrice(shipmentFee) }}đ</div>
            </div>
            <div class="cart-footer">
              <div class="footer-title">
                {{ $t("SupportPage.FillInfoTotalFee") }}
              </div>
              <div class="footer-value">{{ formatPrice(totalPrice) }}đ</div>
            </div>
          </div>
          <div class="mt-3 policy-agree">
            <b-form-checkbox :value="true" v-model="cart_info.agree"></b-form-checkbox>
            <div style="padding-top: 8px">
              {{ $t("SupportPage.AgreeTo") }}
              <span style="color: red; cursor: pointer">{{
                $t("SupportPage.Policity")
              }}</span>
              {{ $t("SupportPage.of iTel") }}
            </div>
          </div>
          <div class="p-2 mt-2" style="padding-bottom: 0 !important">
            <button :class="['fs-group fs-buts confirm-btn']" @click="onConfirmPayment">
              {{ $t("SupportPage.ExchangePaymentOnline") }}
              <div class="img-icon">
                <img src="../assets/images/next-white.png" />
              </div>
            </button>
            <div class="back-btn">
              <button @click="onBack">
                {{ $t("SupportPage.ExchangeTermBack") }}
              </button>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="faci-box" v-if="ConfigData.length > 0">
      <a
        class="hotline-faci"
        :href="'tel:' + ConfigData.find((i) => i.DataKey == 'PhoneWebsite').Value"
      >
        <img loading="lazy" src="@/assets/images/sp-chat-tel.png" alt="hot line" />
      </a>
      <a class="chat-faci" href="https://zalo.me/pc" target="_blank" rel="noopener">
        <img loading="lazy" src="@/assets/images/sp-chat-icon.png" alt="hot chat" />
      </a>
    </div>
  </div>
</template>

<script>
import { required, min, email } from "@validations";
import Vue from "vue";
import Fuse from "fuse.js";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import useJwt from "@/auth/jwt/useJwt";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BForm,
  BCardHeader,
  BCardText,
  BFormCheckbox,
  BInputGroup,
  BImg,
  VBTooltip,
  TooltipPlugin
} from "bootstrap-vue";
import ImgPledgeDemo from "../assets/images/picture.png";
import { v4 as uuidv4 } from "uuid";

Vue.use(TooltipPlugin)

extend("phoneNumber", (value) => {
  if (value.length < 10 || value.length > 11) return false;
  return true;
});

extend("addressValidate", (value) => {
  if (!value || value == 0) return false;
  return true;
});

let debounceHandle = null;

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    BCardHeader,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BInputGroup,
    BImg,
    VBTooltip
  },
  mounted() {
    this.isInIframe = window === window.parent ? false : true;
    if (this.isInIframe) {
      this.$store.dispatch("app/setIframe", true);
      const headH = document.querySelector(".fs-header");
      if (headH) {
        headH.style.display = "none";
      }
      const footer = document.querySelector(".fs-footer");
      if (footer) {
        footer.style.display = "none";
      }
      let css = '#cs-live-chat { display: none !important; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');
      style.type = 'text/css';
      head.appendChild(style);

      if (style.styleSheet){
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }

    this.phoneNumber = this.$route.query.pn;
    this.txnRef = this.$route.query.txnRef;
    this.type = this.$route.query.type ? this.$route.query.type.toLowerCase() : this.type;
    if (this.type == "sim") {
      this.shipmentFee = 15000;
      this.totalPrice = 40000;
    } else {
      this.shipmentFee = 0;
      this.totalPrice = 35000;
    }

    if(this.isInIframe){
      this.shipmentFee = 0;
      if(this.type === "esim"){
        this.paymentMethodIframe = "CK"
        this.changeSimFee = 35000
      }
    }
    this.fetchHotline();
    this.fetchCities();
  },
  data() {
    return {
      step: 1,
      type: "sim",
      txnRef: "",
      Stores: [],
      storeCollapse: true,
      ConfigData: [],
      isLoading: false,
      paymentMethod: "VNPAY",
      vnp_BankCode: "",
      cart_info: {
        fullName: "",
        ContactPhone: "",
        shipment_type: "home",
        addr: "",
        agree: false,
        StoreId: 0,
      },
      Cities: [],
      Districts: [],
      Wards: [],
      Hamlets: [],
      CityId: 0,
      DistrictId: 0,
      WardId: 0,
      HamletId: 0,
      totalPrice: 50000,
      shipmentFee: 0,
      phoneNumber: "",
      changeSimFee: 25000,
      isAgreePolicies: false,
      isInIframe: false,
      pledgeImgSrc: ImgPledgeDemo,
      inputPledgeKey: uuidv4(),
      paymentMethodIframe: "CK",
      deliveryMethodIframe: "fast",
      PaymentImage: null,
      isSelectImage: false
    };
  },

  methods: {
    handleGetShippingFee() {
      if (debounceHandle) {
        clearTimeout(debounceHandle);
      }
      debounceHandle = setTimeout(() => {
        this.getShippingFee();
      }, 500);
    },
    async getShippingFee() {
      if(!this.isInIframe){
        return;
      }
      if(this.type === "esim"){
        this.shipmentFee = 0;
        return
      }
      if(this.cart_info.shipment_type === "itel"){
        this.shipmentFee = 0;
        return
      }
      // if(this.paymentMethodIframe == "CK" && this.deliveryMethodIframe == "fast"){
      //   this.shipmentFee = 15000;
      //   return
      // }
      if(this.deliveryMethodIframe == "fast"){
        this.shipmentFee = 0;

        if(!this.CityId || !this.DistrictId || !this.WardId){
          this.shipmentFee = 0;
          return
        }

        await useJwt
          .get(
            "ghtk/fee?province=" +
            this.CityId +
            "&district=" +
            this.DistrictId +
            "&ward=" +
            this.WardId
          )
          .then((response) => {
            this.shipmentFee = response.data.result.fee.fee
          })
          .catch((err) => {
            this.shipmentFee = 0
        });

        return
      }
      if (
        !(this.CityId && this.DistrictId && this.WardId && this.cart_info.addr)
      ) {
        this.shipmentFee = 0;
        return;
      }

      if(this.deliveryMethodIframe === "express"){
        this.shipmentFee = 0;
        return
      }
      let fullAddress = this.cart_info.addr || "";
      if (this.WardId && this.Wards.find((el) => el.id == this.WardId)) {
        fullAddress += `, ${this.Wards.find((el) => el.id == this.WardId).text
          }`;
      }
      if (
        this.DistrictId &&
        this.Districts.find((el) => el.id == this.DistrictId)
      ) {
        fullAddress += `, ${this.Districts.find((el) => el.id == this.DistrictId).text
          }`;
      }
      if (this.CityId && this.Cities.find((el) => el.id == this.CityId)) {
        fullAddress += `, ${this.Cities.find((el) => el.id == this.CityId).text
          }`;
      }

      await useJwt
        .post("get_fee_and_delivery_time", {
          city_id: this.CityId,
          addr: fullAddress,
          FullName: this.cart_info.fullName,
          ContactPhone: this.cart_info.ContactPhone,
        })
        .then((response) => {
          this.shipmentFee = response?.data?.result?.ahamove_fee || 0;
        })
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.message ||
            "Có lỗi trong khi thực hiện tra cứu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );

          this.shipmentFee = 0;
        });
    },
    onChangePaymentMethodIframe(e) {
      this.paymentMethodIframe = e;

      this.getShippingFee();
    },
    onChangeDelivery(e) {
      this.deliveryMethodIframe = e;

      if(this.deliveryMethodIframe === "express"){
        this.paymentMethodIframe = "CK";
      }

      this.getShippingFee();
    },
    onClickChooseStore() {
      this.storeCollapse = !this.storeCollapse;
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["text", "text1"],
        shouldSort: true,
      });
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list;
    },
    fetchHotline() {
      useJwt
        .post("getMasterData/home", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.ConfigData = response.data.result.ConfigData;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error("Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!", {
            icon: true,
            closeButton: "button",
          });
        });
    },
    async fetchCities() {
      try {
        await useJwt
          .post("getMasterData/Stores1", {
            columnFilters: { IsChangeSim: true },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Stores = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
              "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
              { icon: true, closeButton: "button" }
            );
          });

        const res = await useJwt.post("getMasterData/GHTK_Cities", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });

        this.Cities = res.data.result.map((item) => {
          return {
            id: item.id,
            text: item.text,
          };
        });
        this.Cities.unshift({
          id: 0,
          text: "Tất cả",
        });
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu danh mục Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    async onConfirm() {
      var validate = await this.$refs.paymentRefs.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(this.$t("Error.verifyRequire"), {
          icon: true,
          closeButton: "button",
        });
        return;
      }

      if (this.type == "sim") {
        if (this.cart_info.shipment_type == "itel" && !this.cart_info.StoreId) {
          this.$toast.warning(this.$t("SupportPage.ChooseTransactionOffice"), {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }

      if (!this.isAgreePolicies) {
        this.$toast.warning(this.$t("Error.isAgreePolicyPayment"), {
          icon: true,
          closeButton: "button",
        });
        return;
      }

      this.step = 2;
      this.scrollToTop();
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    onConfirmPayment() {
      if (!this.cart_info.agree) {
        this.$toast.warning(this.$t("Error.isAgreePolicyPayment"), {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.payment();
    },
    onBack() {
      this.cart_info.agree = false;
      this.step = 1;
      this.scrollToTop();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPhoneNumber(value) {
      return value.substring(0, 3) + " " + value.substring(3, value.length);
    },

    getPaymentUrl() {
      switch (this.paymentMethod) {
        case "VNPAY":
          this.vnp_BankCode = "VNPAYQR";
          return "get-vnpay-url-sim";
        case "BANK":
          this.vnp_BankCode = "VNBANK";
          return "get-vnpay-url-sim";
        case "INTCARD":
          this.vnp_BankCode = "INTCARD";
          return "get-vnpay-url-sim";
        case "MOMO":
          return "get-momo-url-sim";
        case "ZALO":
          return "get-zalopay-url-sim";
      }
    },

    async payment() {
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      const paymentUrl = this.getPaymentUrl();
      useJwt
        .post(paymentUrl, {
          Phone: this.phoneNumber,
          TotalPrice: this.totalPrice,
          ShipmentFee: this.getShipmentFee,
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          txnRef: this.txnRef,
          vnp_BankCode: this.vnp_BankCode,
        })
        .then((response) => {
          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
        if(this.type === "sim"){
          window.localStorage.removeItem("formDataSim");
        }
        if(this.type === "esim"){
          window.localStorage.removeItem("formDataEsim");
        }

    },
    change_shipment(val) {
      if (!this.cart_info.shipment_type) {
        this.cart_info.shipment_type = val;
      }
      if(this.isInIframe){
        this.getShippingFee()
      }

      if (this.type == "sim" && !this.isInIframe) {
        if (val == "itel") {
          this.shipmentFee = 0;
          this.totalPrice = 25000;
        } else {
          this.shipmentFee = 15000;
          this.totalPrice = 40000;
        }
      }
    },
    selectStore(id) {
      this.isLoading = true;
      this.cart_info.StoreId = id.Id;
      this.isLoading = false;
      this.storeCollapse = true;
    },
    isNumber(evt) {
      return;
      const keyPressed = evt.key;

      if (
        this.cart_info.ContactPhone &&
        this.cart_info.ContactPhone.length >= 11 &&
        keyPressed !== "Delete" &&
        keyPressed !== "Backspace" &&
        keyPressed !== "ArrowLeft" &&
        keyPressed !== "ArrowRight" &&
        keyPressed !== "ArrowUp" &&
        keyPressed !== "ArrowDown"
      ) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
      ];

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    async onLoadPledge(e) {
      if (e?.target?.files[0]) {
        const obj = e?.target?.files[0];

        this.loading(true);
        let imgBase64= await this.toBase64(obj)
        imgBase64 = imgBase64.replace("data:image/jpeg;base64,", "")
                            .replace("data:image/png;base64,", "")
                            .replace("data:image/jpg;base64,", "")
        useJwt
            .post("ekyc/upload-sim-img", [{data: imgBase64}])
            .then((response) => {
              this.PaymentImage = response.data.result[0].file[0];
              this.pledgeImgSrc = URL.createObjectURL(obj)

              this.loading(false);
              this.isSelectImage = true
            });
      }
      this.inputPledgeKey = uuidv4();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onConfirmSimIframe() {
      var validate = await this.$refs.paymentRefs.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(this.$t("Error.verifyRequire"), {
          icon: true,
          closeButton: "button",
        });
        return;
      }

      if(this.paymentMethodIframe == 'CK'){
        if(!this.PaymentImage){
            this.$toast.warning(this.$t("Error.choosePayment"), {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }

      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;

      const dataSubmit = {
        fullName: this.cart_info.fullName,
        ContactPhone: this.cart_info.ContactPhone,
        addr: this.cart_info.addr,
        PaymentChannel: this.paymentMethodIframe,
        PaymentImage: this.paymentMethodIframe == 'COD' && !(this.type === "esim") ? null : this.PaymentImage,
        IsFast: this.deliveryMethodIframe == 'fast' ? 0 : 1,
        TotalPrice: this.changeSimFee + this.shipmentFee,
        ShipmentFee: this.shipmentFee,
        Phone: this.phoneNumber,
        txnRef: this.txnRef,
        cart_info: this.cart_info
      }

      if(this.type === "sim"){
        window.localStorage.removeItem("formDataSim");
      }
      if(this.type === "esim"){
        window.localStorage.removeItem("formDataEsim");
      }

      parent.postMessage({type: 'onChangeSIMStep2', data: dataSubmit}, '*')
    },
    onPreviewPledgeImage(){
      const preview = this.$imagePreview({
        initIndex:0,
        images:[this.pledgeImgSrc],
        onClose: () => {}
      })
    },
    onEditPledgeImage(){
      this.$refs.inputPledgeKey.click()
    },
  },
  watch: {
    "cart_info.addr": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    CityId: function (i, v) {

      if(i != 1) {
        this.deliveryMethodIframe = 'fast'
      }
      this.getShippingFee();
      this.DistrictId = 0;
      if (this.CityId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Districts", {
            columnFilters: { CityId: this.CityId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Districts = response.data.result;
            this.loading(false);
          });
      }
    },
    DistrictId: function (i, v) {
      this.WardId = 0;
      this.getShippingFee();
      if (this.DistrictId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Wards", {
            columnFilters: { DistrictId: this.DistrictId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Wards = response.data.result;
            this.loading(false);
          });
      }
    },
    WardId: async function (i, v) {
      this.HamletId = 0;
      this.getShippingFee();
      if (this.WardId > 0) {
        this.loading(true);
        await useJwt
          .post("getMasterData/GHTK_Hamlets", {
            columnFilters: { WardId: this.WardId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Hamlets = response.data.result;
            this.loading(false);
          });
        this.isLoadingFee = true;
        await useJwt
          .get(
            "ghtk/fee?province=" +
              this.CityId +
              "&district=" +
              this.DistrictId +
              "&ward=" +
              this.WardId
          )
          .then((response) => {
            this.cart_info.ghtk_fee = response.data.result.fee.fee;
            this.isLoadingFee = false;
          });
      }
    },
  },
};
</script>
<style lang="scss">
.btn-preview-image-container{
  position: absolute;
  width: 100px;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.4);
  display: none;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 8px;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.label-pledge-container:hover .btn-preview-image-container{
  display: flex;
}
div.error-border > div.vs__dropdown-toggle {
  border: 1px solid red;
}
.select-store{
  border: 1px solid rgba(60, 60, 60, .26) !important;
  border-radius: 4px;
  background-position-y: 50% !important;
}
</style>

<style lang="scss" scoped>
.b2c-store-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
    no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 21px;
  cursor: pointer;
}
.b2c-store-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
    no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 21px;
  cursor: pointer;
}

.disabled-btn {
  background: #878787 !important;
}

.receive-method {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  align-items: center;
  padding: 8px 0;
  // border-bottom: 1px solid rgb(218, 218, 218);

  &:last-child {
    border-bottom: none;
  }
}

@media screen and (min-width: 1100px) {
  .cart-body__sp {
    width: 1000px;

    .card-body {
      display: flex;
    }
  }

  .cart-body__sp-2 {
    width: 40% !important;
    .card-body {
      display: inline-block;
    }
    .policy-agree {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .container__sp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
  .cart-body__sp {
    margin: 0;
    // width: 60%;
    border-radius: 4px !important;
    padding: 1rem 0.25rem;
  }
  .ta-c {
    text-align: center;
  }
  .info-phone-number__sp {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background: #f4f5f5;
    border-radius: 4px;
    flex-grow: 1;
    margin: 0 15px;
    flex-grow: 1;
  }

  .select-method-receive {
    padding: 0.5rem 1.5rem;
    padding-right: 1rem;
    background: #f4f5f5;
    font-weight: 600;
  }

  .cart-title {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 600;
    color: #3c3c3c;
  }
  .mb-2dot5 {
    margin-bottom: 25px;
  }
  .divide-line {
    height: 1px;
    background-color: rgb(205, 204, 204);
    width: 100%;
  }
  .big-number {
    font-size: 2.4rem;
    font-weight: 600;
    color: #3c3c3c;
  }

  .fee-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .right-content {
    width: 60%;
  }

  .card-text .row-info {
    flex-direction: column;
    .col-md-6 {
      max-width: 100%;
    }
  }

  .cart-footer {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: #3c3c3c;
  }

  .footer-title {
    font-size: 2.4rem;
  }

  .footer-value {
    font-size: 2.4rem;
  }

  .recive-info-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: #3c3c3c;
  }

  .fw-b {
    font-weight: 500;
  }

  .bs-2 {
    box-shadow: 0px 3px 7px 0px #afaaaa63;
  }

  .confirm-btn {
    display: inline-block;
    padding: 1.4rem 5.55rem;
    background-color: #ff2424;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    border-radius: 10px 4px 10px 4px;
    border: 2px solid rgba(255, 36, 36, 0);
    cursor: pointer;
    text-align: center;
    width: 100%;
    position: relative;
    .img-icon {
      position: absolute;
      right: 18px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 11px;
        height: 16px;
      }
    }
  }

  .fs-3rem {
    font-size: 2.5rem;
  }

  .policy-agree {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .back-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;

    button {
      color: red;
      cursor: pointer;
    }
  }

  .payment-options {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;

    .title-Options {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .content-options {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

@media screen and (max-width: 1100px) {
  .container__sp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
  .cart-body__sp {
    margin: 0;
    // width: 100%;
    border-radius: 4px !important;
    padding: 1rem 0.25rem;
  }
  .ta-c {
    text-align: center;
  }
  .info-phone-number__sp {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: #f4f5f5;
    border-radius: 4px;
  }

  .select-method-receive {
    padding: 0.55rem 1rem;
    padding-left: 1.5rem;
    background: #f4f5f5;
    font-weight: 600;
  }

  .cart-title {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: #3c3c3c;
  }
  .mb-2dot5 {
    margin-bottom: 25px;
  }
  .divide-line {
    height: 0.5px;
    background-color: black;
    width: 100%;
  }
  .big-number {
    font-size: 2.4rem;
    font-weight: 600;
    color: #3c3c3c;
  }
  .fee-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .right-content {
    width: 100%;
  }

  .cart-footer {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: #3c3c3c;
  }

  .footer-title {
    font-size: 1.4rem;
  }

  .footer-value {
    font-size: 2rem;
  }

  .recive-info-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: #3c3c3c;
  }

  .fw-b {
    font-weight: 500;
  }

  .bs-2 {
    box-shadow: 0px 3px 7px 0px #afaaaa63;
  }

  .confirm-btn {
    display: inline-block;
    padding: 1.4rem 5.55rem;
    background-color: #ff2424;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    border-radius: 10px 4px 10px 4px;
    border: 2px solid rgba(255, 36, 36, 0);
    cursor: pointer;
    text-align: center;
    width: 100%;
    position: relative;
    .img-icon {
      position: absolute;
      right: 18px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 11px;
        height: 16px;
      }
    }
  }

  .fs-3rem {
    font-size: 2.5rem;
  }

  .policy-agree {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .back-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;

    button {
      color: red;
      cursor: pointer;
    }
  }
  .payment-options {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;

    .title-Options {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
  .content-options {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
}
</style>
